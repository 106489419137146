.main-page {
  filter: grayscale(0);
  padding-bottom: 0;
  .sale {
    height: 250px;
    .text-xl {
      font-size: 1.2rem;
    }

    .stars {
      top: -10px;
      transform: scale(0.9);
    }

    &-footer {
      background-color: rgba(var(--color-contrast-rgb), 0.95);
      flex-direction: row;
      &-date {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px 10px;
        background-color: rgba(var(--color-contrast-rgb), 0.7);
        border-radius: var(--border-radius);

        .rtl & {
          left: 10px;
          right: auto;
        }
      }

      &-price {
        display: flex;
        flex-direction: row;
        font-size: 0.7rem;
        display: flex;

        div:first-child {
          margin-top: 6px;
          margin-inline-end: 4px;
        }
      }

      &-nights {
        padding-inline-end: 10px;
        border-inline-end: 1px solid var(--color-border);
        margin-inline-end: 10px;
      }
    }

    .mr-10 {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &-offline {
      img {
        margin-top: 70px;
      }

      .title-light {
        margin-bottom: 60px;
      }

      .stars {
        margin-bottom: -10px;
      }

      .sale-footer {
        background-color: rgba(var(--color-contrast-2-rgb));
        &-date {
          top: auto;
          bottom: 10px;
          background-color: rgba(var(--color-contrast-2-rgb));
        }
      }
    }
  }

  &:has(.main-page-bg) {
    .main-page-search {
      margin: 60px auto;
    }
  }
}

.header-logo {
  &-wr {
    display: flex;
  }
  &-club {
    margin-top: 10px;
    margin-inline-start: 10px;
    height: 40px;
  }

  &-text {
    font-weight: 600;
    color: #fff;
    white-space: nowrap;
    margin-top: 15px;
    margin-inline-start: 20px;
    font-size: 1.2rem;
  }
}

.footer {
  &-sublogo {
    display: flex;

    strong {
      color: #fff;
      font-weight: 600;
      margin-inline-start: 150px;
    }
  }
}

.details {
  &-wrapper {
    min-height: 900px;

    iframe {
      min-height: 800px;
      width: 600px;
      max-width: 96%;
    }
  }
}

.ticket-choosen {
  background: rgba(var(--color-contrast-rgb), 0.2) !important;
}

.pay-wrapper {
  .loader-main {
    z-index: 1;
  }
}
.carousel .slide img {
  object-fit: cover;
}

.login-top {
  position: relative;
  z-index: 0;
  background-size: cover;
  height: calc(100vh - 151px);
}

@media (min-width: 1250px) {
  .main-page .sale {
    margin-bottom: 35px;
  }
}

@media (max-width: 1250px) {
  .header-search {
    margin-inline-end: 0 !important;
  }
}
@media (max-width: 768px) {
  .main-page-search-2 {
    height: auto;
  }

  .header-cell-text {
    text-align: right;
  }

  .header-dropdown {
    .cur-poi,
    a,
    .text-center {
      text-align: right !important;
    }
  }

  .footer {
    text-align: right;

    &-social {
      justify-content: flex-end;
    }
    .col-6 {
      flex: 1 0 50%;
    }
  }
}

@media (max-width: 500px) {
  .header-logo-wr {
    max-width: none;
    margin-right: -15px;
  }
}
